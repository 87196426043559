const actions = {
  SET_STATE: 'staff/SET_STATE',
  SHOW_SMS_POPUP: 'staff/SHOW_SMS_POPUP',
  HIDE_SMS_POPUP: 'staff/HIDE_SMS_POPUP',
  SEND_SMS: 'staff/SEND_SMS',
  REMOVE_DATA: 'staff/REMOVE_DATA',
  SHOW_CREATE: 'staff/SHOW_CREATE',
  HIDE_CREATE: 'staff/HIDE_CREATE',
  SHOW_UPDATE: 'staff/SHOW_UPDATE',
  HIDE_UPDATE: 'staff/HIDE_UPDATE',
  SHOW_DELETE: 'staff/SHOW_DELETE',
  HIDE_DELETE: 'staff/HIDE_DELETE',
  LOAD_ALL_USERS: 'staff/LOAD_ALL_USERS',
  LOAD_ORGANISATION_TREE: 'staff/LOAD_ORGANISATION_TREE',
  GETUSERFILTER: 'staff/GETUSERFILTER',
  SHOW_BIRTHDAY_POPUP: 'staff/SHOW_BIRTHDAY_POPUP',
  HIDE_BIRTHDAY_POPUP: 'staff/HIDE_BIRTHDAY_POPUP',
  SEND_BIRTHDAY_GREETINGS: 'staff/SEND_BIRTHDAY_GREETINGS',
  SEND_BULK_SMS: 'staff/SEND_BULK_SMS',
  GETFILTER: 'staff/GETFILTER',
  SHOW_CREATE_CUSTOMER: 'staff/SHOW_CREATE_CUSTOMER',
  HIDE_CREATE_CUSTOMER: 'staff/HIDE_CREATE_CUSTOMER',
  SHOW_UPDATE_CUSTOMERS: 'staff/SHOW_UPDATE_CUSTOMERS',
  HIDE_UPDATE_CUSTOMERS: 'staff/HIDE_UPDATE_CUSTOMERS',

  LOAD_ALL: 'staff/LOAD_ALL',
  LOAD_CUSTOMERS: 'staff/LOAD_CUSTOMERS',
  CREATE: 'staff/CREATE',
  UPDATE: 'staff/UPDATE',
  REMOVE: 'staff/REMOVE',
  SAVE_CUSTOMER: 'staff/SAVE_CUSTOMER',
  UPDATE_CUSTOMER: 'staff/UPDATE_CUSTOMER',
  SHOW_ADVANCE_MODAL: 'staff/SHOW_ADVANCE_MODAL',
  HIDE_ADVANCE_MODAL: 'staff/HIDE_ADVANCE_MODAL',
  LOAD_PAYMENTS: 'staff/LOAD_PAYMENTS',
  SAVE_RECEIPT: 'staff/SAVE_RECEIPT',
  SHOW_BALANCE_MODAL: 'staff/SHOW_BALANCE_MODAL',
  HIDE_BALANCE_MODAL: 'staff/HIDE_BALANCE_MODAL',
  SHOW_ADVANCE_LIST: 'staff/SHOW_ADVANCE_LIST',
}

export default actions
