import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, loadAllUsers, filterUser, loadAll, remove, sendsms, update, sendgreetings, sendbulksms, loadtree } from 'api/staff'
import { createCustomer, getcustomers, updatecustomer, loadpayments, savereceipt, loadadvanceList } from 'api/customer'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}




export function* LOAD_ORGANISATION_TREE() {
  const { response } = yield call(loadtree)
  if (response) {
    const { GetVicePresident, GetDirector, GetBranchManager, GetSalesManager } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        VicePresident: GetVicePresident,
        Director: GetDirector,
        BranchManager: GetBranchManager,
        SalesManager: GetSalesManager,
      },
    })
  }
}




export function* LOAD_ALL_USERS() {
  const { response } = yield call(loadAllUsers)
  if (response) {
    const { allUserlist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        userListInfodata: allUserlist,
      },
    })
  }
}

export function* GETFILTER({ payload }) {
  const { response } = yield call(filterUser, payload)
  if (response) {
    const { getFiltered, getAllUsers, filterName } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        getFilteredUser: getFiltered,
        getAllUsersInfo: getAllUsers,
        currentRoleFilter: filterName,
      },
    })
  }
}

export function* SEND_SMS({ payload }) {
  const { response } = yield call(sendsms, payload?.staffId)
  if (response) {
    const { getFiltered } = response.data
    yield put({
      type: actions.HIDE_SMS_POPUP,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        getFilteredUser: getFiltered,
      },
    })
  }
}

export function* SEND_BIRTHDAY_GREETINGS({ payload }) {
  const { response } = yield call(sendgreetings, payload)
  if (response) {
    yield put({
      type: actions.HIDE_BIRTHDAY_POPUP,
    })
  }
}

export function* SEND_BULK_SMS({ payload }) {
  const { response } = yield call(sendbulksms, payload)
  if (response) {
    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     messageList: response?.data?.messageList,
    //   },
    // })
  }
}

export function* LOAD_CUSTOMERS() {
  const { response } = yield call(getcustomers)
  if (response) {
    const { customerList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerInfo: customerList,
      },
    })
  }
}





export function* SHOW_ADVANCE_LIST({ payload }) {
  const { response } = yield call(loadadvanceList, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        advanceInfo: response?.data?.advanceArray,
        receivedAmount: response?.data?.Received,
        currentAmount: response?.data?.currentTotal,
        displayAdvanceListDrawer: true
      },
    })
  }
}


export function* LOAD_PAYMENTS({ payload }) {
  const { response } = yield call(loadpayments, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        outstandingAmount: Number(response?.data?.outstandingAmount[0]?.cost),
      },
    })
  }
}




export function* SAVE_RECEIPT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(savereceipt, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { customerList } = response.data
    yield put({
      type: actions.HIDE_ADVANCE_MODAL,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerInfo: customerList,
      },
    })
  }
}



export function* UPDATE_CUSTOMER({ payload }) {
  const { UseridSel, updatedData } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatecustomer, UseridSel, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { customerList } = response.data
    yield put({
      type: actions.HIDE_UPDATE_CUSTOMERS,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerInfo: customerList,
      },
    })
  }
}

export function* SAVE_CUSTOMER({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createCustomer, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { customerList } = response.data
    yield put({
      type: actions.HIDE_CREATE_CUSTOMER,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerInfo: customerList,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { UseridSel, updatedData } = payload
  const { response } = yield call(update, UseridSel, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.SEND_SMS, SEND_SMS),
    takeLatest(actions.SEND_BIRTHDAY_GREETINGS, SEND_BIRTHDAY_GREETINGS),
    takeLatest(actions.LOAD_ALL_USERS, LOAD_ALL_USERS),
    takeLatest(actions.LOAD_CUSTOMERS, LOAD_CUSTOMERS),
    takeLatest(actions.GETFILTER, GETFILTER),
    takeLatest(actions.SEND_BULK_SMS, SEND_BULK_SMS),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.SAVE_CUSTOMER, SAVE_CUSTOMER),
    takeEvery(actions.UPDATE_CUSTOMER, UPDATE_CUSTOMER),
    takeEvery(actions.LOAD_PAYMENTS, LOAD_PAYMENTS),
    takeEvery(actions.SAVE_RECEIPT, SAVE_RECEIPT),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.LOAD_ORGANISATION_TREE, LOAD_ORGANISATION_TREE),
    takeEvery(actions.SHOW_ADVANCE_LIST, SHOW_ADVANCE_LIST),
  ])
}
