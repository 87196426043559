export default async function getMenuData() {
  return [
    
    // {
    //   title: 'Dashboard',
    //   key: 'manage-dashboard',
    //   icon: 'fe fe-settings',
    //   url: '/manage/dashboard',
    // },
    // {
    //   title: 'Send Bulk Sms',
    //   key: 'manage-bulk-sms',
    //   icon: 'fe fe-message-square',
    //   url: '/manage/bulk-sms',
    // },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    // {
    //   title: 'Manage Role',
    //   key: 'manage-roles',
    //   icon: 'fe fe-settings',
    //   url: '/manage/roles',
    // },
    {
      title: 'Manage Customers',
      key: 'manage-customers',
      icon: 'fe fe-users',
      url: '/manage/customers',
    },
  ]
}
