import actions from './actions'

const initialState = {
  receivedAmount: null,
  currentAmount: null,
  currentRoleFilter: null,
  staffInfo: null,
  userInfodata: null,
  getFilteredUser: null,
  getAllUsersInfo: null,
  userListInfodata: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  VicePresident: null,
  Director: null,
  BranchManager: null,
  SalesManager: null,
  birthdayFollowup: null,

  displaySmsPopup: false,
  displayBirthdayWishes: false,

  search: null,
  company_data: null,
  outstandingAmount: null,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayAddCustomerDrawer: false,
  displayUpdateCustomerDrawer: false,
  displayAdvanceModal: false,
  displayBalanceModal: false,
  displayAdvanceListDrawer: false,


  displayRemoveModal: false,
  removing: false,
}

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_DATA:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }

    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.staffInfo, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }

    case actions.SHOW_SMS_POPUP:
      return { ...state, selectedData: action.payload.staffInfo, displaySmsPopup: true }
    case actions.HIDE_SMS_POPUP:
      return { ...state, selectedData: null, displaySmsPopup: false }

    case actions.REMOVE_DATA:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter(o => action.payload._id !== o._id),
      }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }

    case actions.SHOW_CREATE_CUSTOMER:
      return { ...state, displayAddCustomerDrawer: true }
    case actions.HIDE_CREATE_CUSTOMER:
      return { ...state, displayAddCustomerDrawer: false }


    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.staffInfo, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }

    case actions.SHOW_UPDATE_CUSTOMERS:
      return { ...state, selectedData: action.payload.customerInfo, displayUpdateCustomerDrawer: true }
    case actions.HIDE_UPDATE_CUSTOMERS:
      return { ...state, displayUpdateCustomerDrawer: false }

    case actions.SHOW_BIRTHDAY_POPUP:
      return { ...state, selectedData: action.payload, displayBirthdayWishes: true }
    case actions.HIDE_BIRTHDAY_POPUP:
      return { ...state, displayBirthdayWishes: false }


    case actions.SHOW_ADVANCE_MODAL:
      return { ...state, selectedData: action.payload.info, displayAdvanceModal: true }

    case actions.HIDE_ADVANCE_MODAL:
      return { ...state, selectedData: null, displayAdvanceModal: false }


    case actions.SHOW_BALANCE_MODAL:
      return { ...state, selectedData: action.payload.info, displayBalanceModal: true }


    case actions.HIDE_BALANCE_MODAL:
      return { ...state, selectedData: null, displayBalanceModal: false }


    default:
      return state
  }
}
