import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function createCustomer(payload) {
  return apiClient
    .post(`${BASE_URL}/savecustomer`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getcustomers() {
  return apiClient
    .get(`${BASE_URL}/getcustomers`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function loadpayments(payload) {
  return apiClient
    .post(`${BASE_URL}/loadpayments`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}



export async function updatecustomer(UseridSel, updatedData) {
  return apiClient
    .put(`${BASE_URL}/updatecustomer/${UseridSel}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function savereceipt(payload) {
  return apiClient
    .post(`${BASE_URL}/savereceipt`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function loadadvanceList(payload) {
  return apiClient
    .post(`${BASE_URL}/loadadvanceList`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


