import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}/getallusers`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAllUsers(payload) {
  return apiClient
    .get(`${BASE_URL}/getalluserinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/createusers`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(UseridSel, updatedData) {
  return apiClient
    .put(`${BASE_URL}/updateusers/${UseridSel}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function sendsms(UseridSel) {
  return apiClient
    .put(`${BASE_URL}/sendsms/${UseridSel}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function sendbulksms(payload) {
  return apiClient
    .post(`${BASE_URL}/sendbulksms`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function sendgreetings(payload) {
  return apiClient
    .post(`${BASE_URL}/sendgreetings`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterUser(userId) {
  return apiClient
    .post(`${BASE_URL}/filterUser/`,userId)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(staffId) {
  return apiClient
    .put(`${BASE_URL}/deleteusers/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadtree() {
  return apiClient
    .get(`${BASE_URL}/generateTree`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

